import React from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "react-intl"
import { getMenuLink } from "../utils/main"
import LangSelector from "../components/lang-selector"
import LogoLightGrayEn from "../images/logo-light-gray-en.png"
import LogoLightGrayZh from "../images/logo-light-gray-zh.png"

const Footer = ({ langs, langKey, defaultLangKey }) => {
  const mapMenuLink = getMenuLink(langKey, defaultLangKey)
  const paths = ["topics", "courses"]
  const [topicsLink, coursesLink] = paths.map(mapMenuLink)
  const LogoLightGray = langKey === "zh" ? LogoLightGrayZh : LogoLightGrayEn
  return (
    <footer className="footer has-background-dark">
      <div className="container">
        <div className="tile is-vertical is-ancestor">
          <div className="tile is-12">
            <div className="tile is-parent is-4">
              <div className="tile is-child">
                <h2 className="title has-text-light">
                  <img
                    src={LogoLightGray}
                    className="footer-logo"
                    alt="mstfe"
                  />
                </h2>
                <p className="has-text-light">
                  <FormattedMessage id="footer-tip" />
                </p>
                <LangSelector langs={langs}></LangSelector>
              </div>
            </div>
            <div className="tile is-parent is-2">
              <div className="footer-menu">
                <div className="footer-menu-item footer-menu-title has-text-light has-text-weight-medium">
                  <FormattedMessage id="footer-menu-solution" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-solution-1" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-solution-2" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-solution-3" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-solution-4" />
                  <Link to="https://vercel.com/">Vercel</Link>
                </div>
              </div>
            </div>
            <div className="tile is-parent is-2">
              <div className="footer-menu">
                <div className="footer-menu-item footer-menu-title has-text-light has-text-weight-medium">
                  <FormattedMessage id="footer-menu-support" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-support-1" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-support-2" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-support-3" />
                </div>
              </div>
            </div>
            <div className="tile is-parent is-2">
              <div className="footer-menu">
                <div className="footer-menu-item footer-menu-title has-text-light has-text-weight-medium">
                  <FormattedMessage id="footer-menu-company" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-company-1" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-company-2" />
                </div>
              </div>
            </div>
            <div className="tile is-parent is-2">
              <div className="footer-menu">
                <div className="footer-menu-item footer-menu-title has-text-light has-text-weight-medium">
                  <FormattedMessage id="footer-menu-legal" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-legal-1" />
                </div>
                <div className="footer-menu-item has-text-white">
                  <FormattedMessage id="footer-menu-legal-2" />
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright tile is-12 has-text-light">
            <FormattedMessage id="footer-copyright" />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
