import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twsite
          }
        }
      }
    `
  )

  const metaDescription =
    description || lang === "en"
      ? site.siteMetadata.description.split("|")[0]
      : site.siteMetadata.description.split("|")[1]
  const metaTitle =
    lang === "en"
      ? site.siteMetadata.title.split("|")[0]
      : site.siteMetadata.title.split("|")[1]
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s - ${metaTitle}`}
      meta={[
        {
          name: `keywords`,
          content: site.siteMetadata.keywords,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.twsite,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `zh`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
