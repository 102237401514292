import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "../layouts/footer"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { graphql, StaticQuery } from "gatsby"
import { IntlProvider } from "react-intl"
import "intl"
import "../styles/main.scss"

const Layout = ({ children, location, i18nMessages, pageType }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        if (location) {
          const url = location.pathname
          const { langs, defaultLangKey } = data.site.siteMetadata.languages
          const langKey = getCurrentLangKey(langs, defaultLangKey, url)
          const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
          const langsMenu = getLangs(
            langs,
            langKey,
            getUrlForLang(homeLink, url)
          ).map(item => ({
            ...item,
            link: item.link.replace(`/${defaultLangKey}/`, "/"),
          }))
          return (
            <IntlProvider locale={langKey} messages={i18nMessages}>
              <>
                <div className={pageType}>
                  <Header
                    pageType={pageType}
                    location={url}
                    langsMenu={langsMenu}
                    langKey={langKey}
                    defaultLangKey={defaultLangKey}
                  ></Header>
                  <main>{children}</main>
                </div>
                <Footer
                  langs={langsMenu}
                  langKey={langKey}
                  defaultLangKey={defaultLangKey}
                ></Footer>
              </>
            </IntlProvider>
          )
        }
      }}
    ></StaticQuery>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
