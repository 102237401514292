import React from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "react-intl"
import { getMenuLink } from "../utils/main"
import LogoColoredEn from "../images/logo-colored-en.png"
import LogoColoredZh from "../images/logo-colored-zh.png"
import HomeBannerBg from "../images/home-banner-bg@2x.png"
import HomeBannerImg from "../images/home-banner-img.svg"

const HomeMenu = ({ mapMenuLink }) => {
  const paths = ["topics", "courses", "guides", "forum", "signup", "login"]
  const [
    topicsLink,
    coursesLink,
    guidesLink,
    forumLink,
    signupLink,
    loginLink,
  ] = paths.map(mapMenuLink)

  return (
    <div id="home-top-menu" className="navbar-menu">
      <div className="navbar-end">
        <div className="navbar-item">
          <Link className="button is-white" to={topicsLink}>
            <FormattedMessage id="menu-topics" />
          </Link>
        </div>
        {/* <div className="navbar-item">
          <Link className="button is-white" to={coursesLink}>
            <FormattedMessage id="menu-courses" />
          </Link>
        </div>
        <div className="navbar-item">
          <Link className="button is-white" to={guidesLink}>
            <FormattedMessage id="menu-guides" />
          </Link>
        </div>
        <div className="navbar-item">
          <Link className="button is-white" to={forumLink}>
            <FormattedMessage id="menu-forum" />
          </Link>
        </div> */}
      </div>
      {/* <div className="navbar-end">
        <div className="navbar-item">
          <div className="buttons">
            <Link className="button is-link" to={signupLink}>
              <FormattedMessage id="menu-signup" />
            </Link>
            <Link className="button is-white" to={loginLink}>
              <FormattedMessage id="menu-login" />
            </Link>
          </div>
        </div>
      </div> */}
    </div>
  )
}

const HomeBanner = ({ mapMenuLink }) => {
  const paths = ["signup", "courses"]
  const [signupLink, coursesLink] = paths.map(mapMenuLink)
  return (
    <section className="hero">
      <img className="home-banner-img" src={HomeBannerImg} alt="mstfe" />
      <div className="hero-body">
        <div className="container">
          <h1 className="title-bg">
            <FormattedMessage id="home-banner-h-1" />
          </h1>
          <h1 className="title-bg has-text-link">
            <FormattedMessage id="home-banner-h-2" />
          </h1>
          <p className="content">
            <FormattedMessage id="home-banner-p-1" /> <br />
            <FormattedMessage id="home-banner-p-2" />
          </p>
          <div className="buttons">
            <Link className="button is-large is-link" to="#">
              <FormattedMessage id="home-banner-begin" />
            </Link>
            <Link className="button is-large is-link is-light" to="#">
              <FormattedMessage id="home-banner-explore" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

const HomeHeader = props => {
  const mapMenuLink = getMenuLink(props.langKey, props.defaultLangKey)
  const rootLink = mapMenuLink("")
  const LogoColored = props.langKey === "zh" ? LogoColoredZh : LogoColoredEn
  return (
    <div
      className="header"
      style={{
        backgroundImage: `url(${HomeBannerBg})`,
        backgroundPosition: "center 2.5rem",
      }}
    >
      <nav className="bd-navbar navbar has-shadow is-spaced is-fixed-top">
        <div className="navbar-brand">
          <Link className="navbar-item" to={rootLink}>
            <img src={LogoColored} alt="mstfe" />
          </Link>
          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="home-top-menu"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <HomeMenu mapMenuLink={mapMenuLink}></HomeMenu>
      </nav>
      {props.pageType === "home" ? (
        <div className="container">
          <HomeBanner mapMenuLink={mapMenuLink}></HomeBanner>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

const NonHomeMenu = ({ mapMenuLink }) => {
  const paths = ["topics", "courses", "guides", "forum", "signup", "login"]
  const [
    topicsLink,
    coursesLink,
    guidesLink,
    forumLink,
    signupLink,
    loginLink,
  ] = paths.map(mapMenuLink)

  return (
    <div id="home-top-menu" className="navbar-menu">
      <div className="navbar-start">
        <div className="navbar-item">
          <Link className="button is-dark" to={topicsLink}>
            <FormattedMessage id="menu-topics" />
          </Link>
        </div>
        {/* <div className="navbar-item">
          <Link className="button is-dark" to={coursesLink}>
            <FormattedMessage id="menu-courses" />
          </Link>
        </div>
        <div className="navbar-item">
          <Link className="button is-dark" to={guidesLink}>
            <FormattedMessage id="menu-guides" />
          </Link>
        </div>
        <div className="navbar-item">
          <Link className="button is-dark" to={forumLink}>
            <FormattedMessage id="menu-forum" />
          </Link>
        </div> */}
      </div>
      {/* <div className="navbar-end">
        <div className="navbar-item">
          <div className="buttons">
            <Link className="button is-link" to={signupLink}>
              <FormattedMessage id="menu-signup" />
            </Link>
            <Link className="button is-white" to={loginLink}>
              <FormattedMessage id="menu-login" />
            </Link>
          </div>
        </div>
      </div> */}
    </div>
  )
}

const NonHomeHeader = props => {
  const mapMenuLink = getMenuLink(props.langKey, props.defaultLangKey)
  const rootLink = mapMenuLink("")
  return (
    <div className="header has-background-dark">
      <div className="container">
        <nav className="navbar mt-0 is-dark">
          <div className="navbar-brand">
            <Link className="navbar-item" to={rootLink}>
              <img src={LogoColored} alt="mstfe" />
              <b>
                <FormattedMessage id="menu-sitename" />
              </b>
            </Link>
            <a
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="home-top-menu"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <NonHomeMenu mapMenuLink={mapMenuLink} />
        </nav>
      </div>
    </div>
  )
}

const Header = ({ langsMenu, langKey, defaultLangKey, pageType }) => {
  return (
    <HomeHeader
      langsMenu={langsMenu}
      langKey={langKey}
      defaultLangKey={defaultLangKey}
      pageType={pageType}
    />
  )
  // return pageType === "home" ? (
  // ) : (
  //   <NonHomeHeader
  //     langsMenu={langsMenu}
  //     langKey={langKey}
  //     defaultLangKey={defaultLangKey}
  //   />
  // )
}

export default Header
