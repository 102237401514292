import React from "react"
import { Link } from "gatsby"
import LanguageIcon from "../images/icons/language.svg"

const langFormat = {
  en: "EN",
  zh: "中",
}

const switchLang = lang => {
  localStorage.setItem("lang", lang)
}

const LangSelector = ({ langs }) => {
  const links = langs.map(lang => (
    <Link
      to={lang.link}
      key={lang.langKey}
      className="langItem"
      onClick={() => {
        switchLang(lang.langKey)
      }}
    >
      <li selected={lang.selected}>{langFormat[lang.langKey]}</li>
    </Link>
  ))

  return (
    <section className="lang-seletor">
      <img src={LanguageIcon} alt="Language" className="language-icon" />
      <ul>{links}</ul>
    </section>
  )
}

export default LangSelector
